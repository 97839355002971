<script lang="ts" setup>
defineProps({
  src: { type: String, required: true },
  alt: { type: String, required: true },
})

const error = ref(false)

const onError = () => {
  error.value = true
}
</script>

<template>
  <span
    class="aspect-square w-full h-full drop-shadow-[0_6px_10px_rgba(0,0,0,0.04)] bg-white border border-zeno-gray-border-light rounded-full inline-block"
  >
    <NuxtImg
      v-if="!error"
      :key="src"
      :alt="alt"
      :src="src"
      class="rounded-full mx-auto inline-block w-full"
      @error="onError"
    />
  </span>
</template>

<style scoped></style>
